// Start actions
const START_APP = 'START_APP';

// Core actions
const SET_STATE = 'SET_STATE';
const SET_STREAMING_TYPE = 'SET_STREAMING_TYPE';
const SET_AVAILABLE_LANGUAGES = 'SET_AVAILABLE_LANGUAGES';
const SET_AVAILABLE_SUBTITLES = 'SET_AVAILABLE_SUBTITLES';
const SET_AVAILABLE_VIDEO_BITRATES = 'SET_AVAILABLE_VIDEO_BITRATES';
const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';
const SET_PREFERRED_LANGUAGE = 'SET_PREFERRED_LANGUAGE';
const SET_CURRENT_SUBTITLE = 'SET_CURRENT_SUBTITLE';
const SET_PREFERRED_SUBTITLE = 'SET_PREFERRED_SUBTITLE';
const SET_CURRENT_SUBTITLES_FONT_FAMILY = 'SET_CURRENT_SUBTITLES_FONT_FAMILY';
const SET_CURRENT_SUBTITLES_FONT_TYPE = 'SET_CURRENT_SUBTITLES_FONT_TYPE';
const SET_CURRENT_SUBTITLES_SIZE = 'SET_CURRENT_SUBTITLES_SIZE';
const SET_CURRENT_SUBTITLES_PRESET = 'SET_CURRENT_SUBTITLES_PRESET';
const TOGGLE_VIDEO_DISPLAY = 'TOGGLE_VIDEO_DISPLAY';
const SET_VOLUME = 'SET_VOLUME';
const SET_LIVE_GAP = 'SET_LIVE_GAP';
const SET_SERVER_LIVE_TIME_OFFSET = 'SET_SERVER_LIVE_TIME_OFFSET';
const SET_SERVER_TIMESTAMP = 'SET_SERVER_TIMESTAMP';
const SET_LIVE_START_TIME = 'SET_LIVE_START_TIME';
const MUTE = 'MUTE';
const UNMUTE = 'UNMUTE';
const SET_PLAYBACK_TIME = 'SET_PLAYBACK_TIME';
const SET_PLAYBACK_RATE = 'SET_PLAYBACK_RATE';
const SET_CORE_PLAYER_VERSION = 'SET_CORE_PLAYER_VERSION';
const SET_SECURITY_LEVEL = 'SET_SECURITY_LEVEL';
const SET_SHOULD_NOT_FETCH_UHD_PLAYSET = 'SET_SHOULD_NOT_FETCH_UHD_PLAYSET';
const SET_IS_CORE_PLAYER_LOCKED = 'SET_IS_CORE_PLAYER_LOCKED';
const SET_LOADED_TIME = 'SET_LOADED_TIME';
const SET_BUFFER_GAP = 'SET_BUFFER_GAP';
const SET_CURRENT_TIME = 'SET_CURRENT_TIME';
const SET_MINIMUM_POSITION = 'SET_MINIMUM_POSITION';
const SET_START_AT_ERROR = 'SET_START_AT_ERROR';
const SET_LIVE_TIME = 'SET_LIVE_TIME';
const SET_VIDEO_DURATION = 'SET_VIDEO_DURATION';
const SET_CORE_PLAYER_TYPE = 'SET_CORE_PLAYER_TYPE';
const PLAY = 'PLAY';
const STOP = 'STOP';
const REQUEST_VIDEO_PRELOAD = 'REQUEST_VIDEO_PRELOAD';
const RESET_VIDEO_PRELOAD = 'RESET_VIDEO_PRELOAD';
const SET_DIRECTFILE_REQUEST = 'SET_DIRECTFILE_REQUEST';
const SET_DIRECTFILE_SUCCESS = 'SET_DIRECTFILE_SUCCESS';
const SET_DIRECTFILE_FAILURE = 'SET_DIRECTFILE_FAILURE';
const SET_DIRECTFILE = 'SET_DIRECTFILE';
const SET_MANIFEST_REQUEST = 'SET_MANIFEST_REQUEST';
const SET_MANIFEST_SUCCESS = 'SET_MANIFEST_SUCCESS';
const SET_MANIFEST_FAILURE = 'REQUEST_SET_MANIFEST_FAILED';
const SET_MANIFEST = 'SET_MANIFEST';
const SET_MANIFEST_FROM_RECEIVER_REQUEST = 'SET_MANIFEST_FROM_RECEIVER_REQUEST';
const SET_MANIFEST_FROM_RECEIVER = 'SET_MANIFEST_FROM_RECEIVER';
const UPDATE_GOOGLE_DAI_URL = 'UPDATE_GOOGLE_DAI_URL';
const PAUSE = 'PAUSE';
const RESTART_REQUEST = 'RESTART_REQUEST';
const SEEK_TO = 'SEEK_TO';
const SEEK_TO_MINUS_FIXED_DURATION_REQUEST =
  'SEEK_TO_MINUS_FIXED_DURATION_REQUEST';
const SEEK_TO_PLUS_FIXED_DURATION_REQUEST =
  'SEEK_TO_PLUS_FIXED_DURATION_REQUEST';
const SET_KEY_PRESS_LISTENER_STATUS = 'SET_KEY_PRESS_LISTENER_STATUS';
const GO_TO_LIVE_REQUEST = 'GO_TO_LIVE_REQUEST';
const SET_LAST_TRACKED_USER_COMMAND = 'SET_LAST_TRACKED_USER_COMMAND';
const RESET_LAST_TRACKED_USER_COMMAND = 'RESET_LAST_TRACKED_USER_COMMAND';
const RESET_START_AT_ERROR = 'RESET_START_AT_ERROR';
const REQUEST_UNMUTE_WITH_MOBILE_POPIN = 'REQUEST_UNMUTE_WITH_MOBILE_POPIN';

const SET_FULLSCREEN = 'SET_FULLSCREEN';
const SET_ACTIVITY = 'SET_ACTIVITY';
const RESUME_AD = 'RESUME_AD';
const PAUSE_AD = 'PAUSE_AD';
const SET_AUDIO_BITRATE = 'SET_AUDIO_BITRATE';
const SET_VIDEO_BITRATE = 'SET_VIDEO_BITRATE';
const SET_MAX_VIDEO_BITRATE = 'SET_MAX_VIDEO_BITRATE';
const SET_VIDEO_QUALITY_PROFILE = 'SET_VIDEO_QUALITY_PROFILE';
const SET_CURRENT_KEY_SYSTEM = 'SET_CURRENT_KEY_SYSTEM';
const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
const SET_CURRENT_DISTRIBUTION = 'SET_CURRENT_DISTRIBUTION';
const SET_ESTIMATED_VIDEO_BITRATE = 'SET_ESTIMATED_VIDEO_BITRATE';
const SET_ESTIMATED_AUDIO_BITRATE = 'SET_ESTIMATED_AUDIO_BITRATE';

// CoreActions - Picture-in-Picture
const SET_PIP_IS_ACTIVE = 'SET_PIP_IS_ACTIVE';

// User actions
const SET_PASS_TOKEN = 'SET_PASS_TOKEN';
const SET_MICRO_ELIGIBILITY = 'SET_MICRO_ELIGIBILITY';
const SET_PASS_ID = 'SET_PASS_ID';
const SET_USERKEYID = 'SET_USERKEYID';
const SET_TRACKINGKEYID = 'SET_TRACKINGKEYID';
const SET_DEVICEKEYID = 'SET_DEVICEKEYID';
const SET_PROFILEID = 'SET_PROFILEID';
const SET_APPSESSIONID = 'SET_APPSESSIONID';
const SET_LIVE_TOKEN = 'SET_LIVE_TOKEN';
const SET_STREAM_TOKEN = 'SET_STREAM_TOKEN';
const SET_AS_LIVE = 'SET_AS_LIVE';
const SET_AS_PREMIUM = 'SET_AS_PREMIUM';
const LOCK_PARENTAL_RATING5 = 'LOCK_PARENTAL_RATING5';
const UNLOCK_PARENTAL_RATING5 = 'UNLOCK_PARENTAL_RATING5';
const CHECK_PARENTAL_CODE = 'CHECK_PARENTAL_CODE';
const RESET_PARENTAL_CODE_CHECK = 'RESET_PARENTAL_CODE_CHECK';
const SET_PARENTAL_CODE_ERROR = 'SET_PARENTAL_CODE_ERROR';
const SET_PARENTAL_CODE_USER_DATA = 'SET_PARENTAL_CODE_USER_DATA';
const SET_PARENTAL_CODE_CREATION_QR_CODE_URL =
  'SET_PARENTAL_CODE_CREATION_QR_CODE_URL';
const FETCH_PARENTAL_CODE_CREATION_QR_CODE_URL =
  'FETCH_PARENTAL_CODE_CREATION_QR_CODE_URL';
const SET_PARENTAL_LINKS = 'SET_PARENTAL_LINKS';
const FETCH_PARENTAL_LINKS = 'FETCH_PARENTAL_LINKS';

const SET_UNLOCKED_BROADCAST_ID = 'SET_UNLOCKED_BROADCAST_ID';
const SET_TOKEN_CMS = 'SET_TOKEN_CMS';
const SET_AB_TEST_POPULATION = 'SET_AB_TEST_POPULATION';
const SET_MSD = 'SET_MSD';

// Data actions
const LOAD_BIF = 'LOAD_BIF';
const SET_IMAGES = 'SET_IMAGES';
const SET_STREAM_EVENT_OVERLAY = 'SET_STREAM_EVENT_OVERLAY';
const REMOVE_STREAM_EVENT_OVERLAY = 'REMOVE_STREAM_EVENT_OVERLAY';
const FETCH_CONTENT_REQUEST = 'FETCH_CONTENT_REQUEST';
const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS';
const FETCH_CONTENT_FAILURE = 'FETCH_CONTENT_FAILURE';
const MERGE_DATA = 'MERGE_DATA';
const INIT_PREMIUM_CATCHUP_REQUEST = 'INIT_PREMIUM_CATCHUP_REQUEST';

// Experimental features actions
const TOGGLE_EXPERIMENTAL_FEATURE = 'TOGGLE_EXPERIMENTAL_FEATURE';

// Channel actions
const FETCH_ALL_CHANNELS_REQUEST = 'FETCH_ALL_CHANNELS_REQUEST';
const FETCH_ALL_CHANNELS_SUCCESS = 'FETCH_ALL_CHANNELS_SUCCESS';
const FETCH_ALL_CHANNELS_FAILURE = 'FETCH_ALL_CHANNELS_FAILURE';
const FETCH_CHANNEL_PROGRAMS_REQUEST = 'FETCH_CHANNEL_PROGRAMS_REQUEST';
const FETCH_CHANNEL_PROGRAMS_SUCCESS = 'FETCH_CHANNEL_PROGRAMS_SUCCESS';
const FETCH_CHANNEL_PROGRAMS_FAILURE = 'FETCH_CHANNEL_PROGRAMS_FAILURE';
const FETCH_ALL_PROGRAMS_REQUEST = 'FETCH_ALL_PROGRAMS_REQUEST';
const FETCH_ALL_PROGRAMS_SUCCESS = 'FETCH_ALL_PROGRAMS_SUCCESS';
const FETCH_ALL_PROGRAMS_FAILURE = 'FETCH_ALL_PROGRAMS_FAILURE';

const SET_ZAPLIST_MODE = 'SET_ZAPLIST_MODE';
const SET_ZAPLIST_MENU = 'SET_ZAPLIST_MENU';
const SET_ZAPLIST_SEARCH_QUERY = 'SET_ZAPLIST_SEARCH_QUERY';

const SET_CURRENT_CHANNEL_FROM_PROGRAM_REQUEST =
  'SET_CURRENT_CHANNEL_FROM_PROGRAM_REQUEST';
const SET_CURRENT_CHANNEL_REQUEST = 'SET_CURRENT_CHANNEL_REQUEST';
const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';
const RELOAD_CURRENT_CHANNEL = 'RELOAD_CURRENT_CHANNEL';

// Program action
const SET_CURRENT_PROGRAM_REQUEST = 'SET_CURRENT_PROGRAM_REQUEST';
const SET_CURRENT_PROGRAM = 'SET_CURRENT_PROGRAM';
const SET_HAD_ERROR_ON_CURRENT_PROGRAM = 'SET_HAD_ERROR_ON_CURRENT_PROGRAM';
const SET_IS_ANTI_AD_SKIP_SEQUENCE = 'SET_IS_ANTI_AD_SKIP_SEQUENCE';
const SET_TIMELINE_MODE = 'SET_TIMELINE_MODE';
const SET_TIMELINE_MENU = 'SET_TIMELINE_MENU';
const SET_PREV_NEXT_PROGRAMS = 'SET_PREV_NEXT_PROGRAMS';
const SET_EMPTY_PROGRAM = 'SET_EMPTY_PROGRAM';

// Error actions
const ERROR_SHOW = 'ERROR_SHOW';
const ERROR_RESET = 'ERROR_RESET';

// Reload actions
const START_RELOAD_COUNTDOWN = 'START_RELOAD_COUNTDOWN';
const DECREMENT_RELOAD_COUNTDOWN = 'DECREMENT_RELOAD_COUNTDOWN';
const RELOAD_PLAYER = 'RELOAD_PLAYER';

// Tooltip actions
const TOOLTIP_SET_POSITION = 'TOOLTIP_SET_POSITION';
const TOOLTIP_MOUSE_SHOW = 'TOOLTIP_MOUSE_SHOW';
const TOOLTIP_SHOW = 'TOOLTIP_SHOW';
const TOOLTIP_HIDE = 'TOOLTIP_HIDE';
const TOOLTIP_SET_CONTENT = 'TOOLTIP_SET_CONTENT';

// Spinner actions
const SPINNER_SHOW = 'SPINNER_SHOW';
const SPINNER_HIDE = 'SPINNER_HIDE';

// Frame actions
const FRAME_SET_OFFSETS = 'FRAME_SET_OFFSETS';
const FRAME_SET_SIZE = 'FRAME_SET_SIZE';
const FRAME_SET_FOCUS = 'FRAME_SET_FOCUS';
const FRAME_SET_BREAKPOINT = 'FRAME_SET_BREAKPOINT';

// Config actions
const CONFIG_MERGE = 'MERGE_CONFIG';
const CONFIG_SET_START_AT = 'CONFIG_SET_START_AT';
const CONFIG_RESET_LANGUAGE_PREFERENCES = 'CONFIG_RESET_LANGUAGE_PREFERENCES';
const CONFIG_SET_SUPPORTED_DRMS = 'CONFIG_SET_SUPPORTED_DRMS';

// Key mapping actions
const SET_KEY_MAPPING = 'SET_KEY_MAPPING';
const KEY_PRESS = 'KEY_PRESS';
const SEQUENCE_PRESS = 'SEQUENCE_PRESS';

// Sequence actions
const GO_TO_NEXT_SEQUENCE = 'GO_TO_NEXT_SEQUENCE';
const SEQUENCE_INITIALIZE = 'SEQUENCE_INITIALIZE';
const POSTER_SEQUENCE_REQUEST = 'POSTER_SEQUENCE_REQUEST';
const CONTENT_SEQUENCE_REQUEST = 'CONTENT_SEQUENCE_REQUEST';
const SEQUENCE_INSERT_AFTER_CURRENT = 'SEQUENCE_INSERT_AFTER_CURRENT';
const REPLACE_CURRENT_SEQUENCE = 'REPLACE_CURRENT_SEQUENCE';
const SET_RECEIVER_SEQUENCE = 'SET_RECEIVER_SEQUENCE';
const STORE_SENDER_SEQUENCE = 'STORE_SENDER_SEQUENCE';
const RELEASE_SENDER_SEQUENCE = 'RELEASE_SENDER_SEQUENCE';
const ENTER_CAST_SEQUENCE_REQUEST = 'ENTER_CAST_SEQUENCE_REQUEST';
const LEAVE_CAST_SEQUENCE_REQUEST = 'LEAVE_CAST_SEQUENCE_REQUEST';
const INSERT_CURRENT_SEQUENCE = 'INSERT_CURRENT_SEQUENCE';

// Devices actions
const DEVICE_SET_INFOS = 'DEVICE_SET_INFOS';
const DEVICE_SET_MOTION = 'DEVICE_SET_MOTION';
const DEVICE_SET_ORIENTATION = 'DEVICE_SET_ORIENTATION';

// Ads
const FETCH_ADSERVER_REQUEST = 'FETCH_ADSERVER_REQUEST';
const FETCH_ADSERVER_SUCCESS = 'FETCH_ADSERVER_SUCCESS';
const FETCH_ADSERVER_FAILURE = 'FETCH_ADSERVER_FAILURE';
const ADD_AD_ROUTINES_DATA = 'ADD_AD_ROUTINES_DATA';
const SET_CURRENT_AD_PLAYLIST = 'SET_CURRENT_AD_PLAYLIST';
const ABORT_AD = 'ABORT_AD';
const NEXT_AD = 'NEXT_AD';
const DECREMENT_REMAINING_TIME = 'DECREMENT_REMAINING_TIME';
const TRACK_CLICK = 'TRACK_CLICK';
const SET_AD_FOCUS = 'SET_AD_FOCUS';
const HIDE_AD_SKIP_BUTTONS = 'HIDE_AD_SKIP_BUTTONS';
const SHOULD_SHOW_AD_SKIP_BUTTONS = 'SHOULD_SHOW_AD_SKIP_BUTTONS';
const SET_VPAID = 'SET_VPAID';
const RESET_AD = 'RESET_AD';
const SET_AD_WILL_START_IN = 'SET_AD_WILL_START_IN';
const RESET_AD_WILL_START_IN = 'RESET_AD_WILL_START_IN';
const SET_LAST_AD_BREAK_PLAY_DATE = 'SET_LAST_AD_BREAK_PLAY_DATE';
const INCREMENT_BUFFERING_NUMBER_COUNT = 'INCREMENT_BUFFERING_NUMBER_COUNT';
const RESET_BUFFERING_NUMBER_COUNT = 'RESET_BUFFERING_NUMBER_COUNT';
const CAN_SKIP_CURRENT_AD = 'CAN_SKIP_CURRENT_AD';
const CAN_SKIP_ALL_ADS = 'CAN_SKIP_ALL_ADS';
const SET_CAN_SKIP_AFTER = 'SET_CAN_SKIP_AFTER';
const SET_SPONSOR_GENRE = 'SET_SPONSOR_GENRE';
const SET_DISABLE_SPONSORS = 'SET_DISABLE_SPONSORS';
const AD_START_PLAYING = 'AD_START_PLAYING';
const SET_AD_SCHEDULE = 'SET_AD_SCHEDULE';
const SHOULD_SHOW_ANTI_AD_SKIP_NOTIFICATION =
  'SHOULD_SHOW_ANTI_AD_SKIP_NOTIFICATION';
const SET_STREAM_EVENT_ADS = 'SET_STREAM_EVENT_ADS';
const REMOVE_STREAM_EVENT_ADS = 'REMOVE_STREAM_EVENT_ADS';

// SidePanel
const OPEN_SIDE_PANEL = 'OPEN_SIDE_PANEL';
const CLOSE_SIDE_PANEL = 'CLOSE_SIDE_PANEL';

// Modal actions
const MODAL_OPEN = 'MODAL_OPEN';
const MODAL_CLOSE = 'MODAL_CLOSE';
const MODAL_TOGGLE = 'MODAL_TOGGLE';
const SET_IS_MODAL_TO_BE_DISPLAYED_AGAIN = 'SET_IS_MODAL_TO_BE_DISPLAYED_AGAIN';

// UI Actions
const ADD_NAVIGATION_HISTORY = 'ADD_NAVIGATION_HISTORY';
const REMOVE_NAVIGATION_HISTORY = 'REMOVE_NAVIGATION_HISTORY';
const RESET_NAVIGATION_HISTORY = 'RESET_NAVIGATION_HISTORY';
const UPDATE_DISPLAY_STATE = 'UPDATE_DISPLAY_STATE';
const UPDATE_FOCUSED_ELEMENT = 'UPDATE_FOCUSED_ELEMENT';
const GO_BACK = 'GO_BACK';
const RESET_UI = 'RESET_UI';
const ON_DOWN_EXIT_BUTTONS = 'ON_DOWN_EXIT_BUTTONS';
const ON_UP_EXIT_PANEL = 'ON_UP_EXIT_PANEL';
const OPEN_PANEL = 'OPEN_PANEL';
const UPDATE_PANEL_PARAMS = 'UPDATE_PANEL_PARAMS';
const SET_IS_LG_POINTER_ENABLED = 'SET_IS_LG_POINTER_ENABLED';

// Series
const FETCH_SERIES_REQUEST = 'FETCH_SERIES_REQUEST';
const FETCH_SERIES_SUCCESS = 'FETCH_SERIES_SUCCESS';
const SET_CURRENT_SEASON = 'SET_CURRENT_SEASON';
const SET_EPISODE_PROGRESS = 'SET_EPISODE_PROGRESS';
const SET_CURRENT_EPISODE_PROGRESS = 'SET_CURRENT_EPISODE_PROGRESS';
const RESET_TO_INITIAL_SEASON = 'RESET_TO_INITIAL_SEASON';
const SET_CURRENT_EPISODE_ID = 'SET_CURRENT_EPISODE_ID';
const SET_NEXT_EPISODE_ID = 'SET_NEXT_EPISODE_ID';
const SET_SYNOPSIS_DISPLAY = 'SET_SYNOPSIS_DISPLAY';
const LOAD_EPISODE_REQUEST = 'LOAD_EPISODE_REQUEST';
const LOAD_NEXT_EPISODE_REQUEST = 'LOAD_NEXT_EPISODE_REQUEST';
const ADD_SKIP_BUTTONS = 'ADD_SKIP_BUTTONS';
const DISABLE_FORCE_SHOW_SKIP_BUTTONS = 'DISABLE_FORCE_SHOW_SKIP_BUTTONS';
const RESET_SKIP_BUTTONS = 'RESET_SKIP_BUTTONS';
const HIDE_SKIP_BUTTONS = 'HIDE_SKIP_BUTTONS';
const RESET_SERIES_DATA = 'RESET_SERIES_DATA';
const CANCEL_COUNTDOWN_ENDCREDITS = 'CANCEL_COUNTDOWN_ENDCREDITS';
const RESET_COUNTDOWN_ENDCREDITS = 'RESET_COUNTDOWN_ENDCREDITS';

// Context menu actions
const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
const HIDE_CONTEXT_MENU = 'HIDE_CONTEXT_MENU';
const TOGGLE_RX_PLAYER_DEBUG_MODAL = 'TOGGLE_RX_PLAYER_DEBUG_MODAL';

// PingLog actions
const PING_LOG_ERROR = 'PING_LOG_ERROR';
const PING_LOG_SET_CONFIG = 'PING_LOG_SET_CONFIG';

// SwitchPlus
const SWITCH_PLUS_TAG_ACTION = 'SWITCH_PLUS_TAG_ACTION';
const SWITCH_PLUS_FLUSH = 'SWITCH_PLUS_FLUSH';

// Premium live actions
const INIT_PREMIUM_LIVE_REQUEST = 'INIT_PREMIUM_LIVE_REQUEST';

// Multi
const MULTI_PLAYER_REQUEST = 'MULTI_PLAYER_REQUEST';
const SET_MULTI_LIVE_DATA = 'SET_MULTI_LIVE_DATA';
const SET_MULTI_HAPI_DATA = 'SET_MULTI_HAPI_DATA';

// Live Tv Actions
const SET_LIVE_TV_IS_INITIATED = 'SET_LIVE_TV_IS_INITIATED';
const STOP_LIVE_TV = 'STOP_LIVE_TV';
const START_POLL_PROGRAMS_INTERVAL = 'START_POLL_PROGRAMS_INTERVAL';
const STOP_POLL_PROGRAMS_INTERVAL = 'STOP_POLL_PROGRAMS_INTERVAL';

// API actions
const TRIGGER_EXIT = 'TRIGGER_EXIT';

// Trigger element actions
const DISABLED_ELEMENT_TRIGGERED = 'DISABLED_ELEMENT_TRIGGERED';

// Cast actions
const CAST_AVAILABLE = 'CAST_AVAILABLE';
const CAST_PENDING = 'CAST_PENDING';
const CAST_IDLE_AFTER_PLAYING = 'CAST_IDLE_AFTER_PLAYING';
const CAST_SET_METADATA = 'CAST_SET_METADATA';
const CAST_SET_AVAILABLE_LANGUAGES = 'CAST_SET_AVAILABLE_LANGUAGES';
const CAST_SET_AVAILABLE_SUBTITLES = 'CAST_SET_AVAILABLE_SUBTITLES';
const CAST_SET_CURRENT_LANGUAGE = 'CAST_SET_CURRENT_LANGUAGE';
const CAST_SET_CURRENT_SUBTITLE = 'CAST_SET_CURRENT_SUBTITLE';
const CAST_SUCCEED = 'CAST_SUCCEED';
const CAST_FAILED = 'CAST_FAILED';
const CAST_ENDED = 'CAST_ENDED';
const CAST_REQUEST_START = 'CAST_REQUEST_START';
const CAST_REQUEST_STOP = 'CAST_REQUEST_STOP';
const CAST_SET_RECEIVER_NAME = 'CAST_SET_RECEIVER_NAME';
const CAST_SET_RECEIVER_ID = 'CAST_SET_RECEIVER_ID';
const CAST_SET_CURRENT_REQUEST_FROM_RECEIVER =
  'CAST_SET_CURRENT_REQUEST_FROM_RECEIVER';
const CAST_RECEIVER_ERROR = 'CAST_RECEIVER_ERROR';
const CAST_SET_RELOAD_CONTENT = 'CAST_SET_RELOAD_CONTENT';
const CAST_LOADED = 'CAST_LOADED';
const CAST_LAST_TIME_BEFORE_END = 'CAST_LAST_TIME_BEFORE_END';
const CAST_SET_RECEIVER_REFERENCE_DATE_TIME =
  'CAST_SET_RECEIVER_REFERENCE_DATE_TIME';

// AIRPLAY actions
const TRIGGER_AIRPLAY = 'TRIGGER_AIRPLAY';
const SET_AIRPLAY_AVAILABLE = 'SET_AIRPLAY_AVAILABLE';

// Player actions
const DISPOSE = 'DISPOSE';

// Hodor actions
const CLEAR_MORE_INFOS = 'CLEAR_MORE_INFOS';
const FETCH_MORE_INFOS_REQUEST = 'FETCH_MORE_INFOS_REQUEST';
const FETCH_MORE_INFOS_SUCCESS = 'FETCH_MORE_INFOS_SUCCESS';
const FETCH_MORE_INFOS_FAILURE = 'FETCH_MORE_INFOS_FAILURE';
const RESET_MORE_INFOS_ERROR = 'RESET_MORE_INFOS_ERROR';

// Expert mode actions
const FETCH_EXPERTMODE_RUBRIQUES_REQUEST = 'FETCH_EXPERTMODE_RUBRIQUES_REQUEST';
const FETCH_EXPERTMODE_TEMPLATE_REQUEST = 'FETCH_EXPERTMODE_TEMPLATE_REQUEST';
const FETCH_EXPERTMODE_SUCCESS = 'FETCH_EXPERTMODE_SUCCESS';
const FETCH_EXPERTMODE_FAILURE = 'FETCH_EXPERTMODE_FAILURE';
const FETCH_EXPERTMODE_RATING_FAILURE = 'FETCH_EXPERTMODE_RATING_FAILURE';
const FETCH_EXPERTMODE_TIMELINE_FAILURE = 'FETCH_EXPERTMODE_TIMELINE_FAILURE';
const SET_EXPERTMODE_HIGHLIGHT_MANIFEST = 'SET_EXPERTMODE_HIGHLIGHT_MANIFEST';
const FETCH_EXPERTMODE_RATING_REQUEST = 'FETCH_EXPERTMODE_RATING_REQUEST';
const ADD_NEW_SURVEY_VOTE = 'ADD_NEW_SURVEY_VOTE';
const ADD_NEW_TIMELINE_ANSWER = 'ADD_NEW_TIMELINE_ANSWER';
const SET_EXPERTMODE_TIMELINE_ANSWER_REQUEST =
  'SET_EXPERTMODE_TIMELINE_ANSWER_REQUEST';
const SET_EXPERTMODE_RUBRIQUES_RESPONSE = 'SET_EXPERTMODE_RUBRIQUES_RESPONSE';
const SET_EXPERTMODE_TEMPLATE_RESPONSE = 'SET_EXPERTMODE_TEMPLATE_RESPONSE';
const SET_EXPERTMODE_MENU = 'SET_EXPERTMODE_MENU';
const SET_EXPERTMODE_RUBRIQUE_INDEX = 'SET_EXPERTMODE_RUBRIQUE_INDEX';
const SET_EXPERTMODE_CURRENT_TEMPLATE = 'SET_EXPERTMODE_CURRENT_TEMPLATE';
const SET_EXPERTMODE_SURVEY_RATING_CONTENT_ID =
  'SET_EXPERTMODE_SURVEY_RATING_CONTENT_ID';
const SET_EXPERT_MODE_TIMELINE_ACTIVE_QUESTION =
  'SET_EXPERT_MODE_TIMELINE_ACTIVE_QUESTION';
const REFRESH_EXPERTMODE_TEMPLATE_REQUEST =
  'REFRESH_EXPERTMODE_TEMPLATE_REQUEST';
const TOGGLE_EXPERTMODE_RATING = 'TOGGLE_EXPERTMODE_RATING';
const CLEAN_EXPERTMODE_STRATES = 'CLEAN_EXPERTMODE_STRATES';
const CLEAN_EXPERTMODE_ERROR = 'CLEAN_EXPERTMODE_ERROR';
const CLEAN_EXPERTMODE_RATING_ERROR = 'CLEAN_EXPERTMODE_RATING_ERROR';
const CLEAR_EXPERTMODE_RUBRIQUES = 'CLEAR_EXPERTMODE_RUBRIQUES';
const CLEAN_EXPERTMODE_TIMELINE_ERROR = 'CLEAN_EXPERTMODE_TIMELINE_ERROR';
const CLEAN_EXPERTMODE_TIMELINE_ACTIVE_QUESTION =
  'CLEAN_EXPERTMODE_TIMELINE_ACTIVE_QUESTION';
const CLEAR_EXPERTMODE_STATUS = 'CLEAR_EXPERTMODE_STATUS';
const NOTIFY_EXPERTMODE = 'NOTIFY_EXPERTMODE';
const CLEAR_EXPERTMODE_NOTIFICATION = 'CLEAR_EXPERTMODE_NOTIFICATION';
const SEND_EXPERTMODE_TEMPLATE_TRACKING = 'SEND_EXPERTMODE_TEMPLATE_TRACKING';
const FETCH_EXPERTMODE = 'FETCH_EXPERTMODE';

// Notification
const SET_WAITING_NOTIFICATION = 'SET_WAITING_NOTIFICATION';
const CLEAR_WAITING_NOTIFICATION = 'CLEAR_WAITING_NOTIFICATION';

// i18n
const SET_UILANGUAGE = 'SET_UILANGUAGE';
const SET_UILANGUAGE_AND_PHRASES = 'SET_UILANGUAGE_AND_PHRASES';

// PARENTAL RATING
const SET_PARENTAL_RATING_PAUSED_STATE = 'SET_PARENTAL_RATING_PAUSED_STATE';

// Miniplayer actions
const OPEN_MINIPLAYER_FULLFRAME = 'OPEN_MINIPLAYER_FULLFRAME';
const CLOSE_MINIPLAYER_FULLFRAME = 'CLOSE_MINIPLAYER_FULLFRAME';

// ACTIONS GROUPED
const CORE_REDUCER_ACTIONS = [
  TOGGLE_VIDEO_DISPLAY,
  SET_STATE,
  SET_STREAMING_TYPE,
  SET_VOLUME,
  MUTE,
  UNMUTE,
  SET_LOADED_TIME,
  SET_BUFFER_GAP,
  SET_CURRENT_TIME,
  SET_MINIMUM_POSITION,
  SET_LIVE_TIME,
  SET_VIDEO_DURATION,
  SET_CORE_PLAYER_TYPE,
  PLAY,
  RESUME_AD,
  REQUEST_VIDEO_PRELOAD,
  RESET_VIDEO_PRELOAD,
  SET_DIRECTFILE,
  SET_MANIFEST,
  SET_MANIFEST_FROM_RECEIVER,
  UPDATE_GOOGLE_DAI_URL,
  PAUSE,
  PAUSE_AD,
  STOP,
  SEEK_TO,
  SET_KEY_PRESS_LISTENER_STATUS,
  SET_FULLSCREEN,
  SET_ACTIVITY,
  SET_AVAILABLE_LANGUAGES,
  SET_AVAILABLE_SUBTITLES,
  SET_AVAILABLE_VIDEO_BITRATES,
  SET_CURRENT_LANGUAGE,
  SET_CURRENT_SUBTITLE,
  SET_CURRENT_SUBTITLES_FONT_FAMILY,
  SET_CURRENT_SUBTITLES_FONT_TYPE,
  SET_CURRENT_SUBTITLES_SIZE,
  SET_CURRENT_SUBTITLES_PRESET,
  SET_PREFERRED_LANGUAGE,
  SET_PREFERRED_SUBTITLE,
  SET_LIVE_GAP,
  SET_SERVER_LIVE_TIME_OFFSET,
  SET_SERVER_TIMESTAMP,
  SET_LIVE_START_TIME,
  SET_PLAYBACK_TIME,
  SET_PLAYBACK_RATE,
  SET_CORE_PLAYER_VERSION,
  SET_SECURITY_LEVEL,
  SET_SHOULD_NOT_FETCH_UHD_PLAYSET,
  SET_VIDEO_BITRATE,
  SET_MAX_VIDEO_BITRATE,
  SET_VIDEO_QUALITY_PROFILE,
  SET_AUDIO_BITRATE,
  SET_CURRENT_KEY_SYSTEM,
  SET_CURRENT_LOCATION,
  SET_CURRENT_DISTRIBUTION,
  SET_ESTIMATED_VIDEO_BITRATE,
  SET_ESTIMATED_AUDIO_BITRATE,
  SET_PIP_IS_ACTIVE,
  SET_START_AT_ERROR,
  SET_IS_CORE_PLAYER_LOCKED,
  RESET_START_AT_ERROR,
  SET_LAST_TRACKED_USER_COMMAND,
  RESET_LAST_TRACKED_USER_COMMAND,
  REQUEST_UNMUTE_WITH_MOBILE_POPIN,
];

const CHANNEL_REDUCER_ACTIONS = [
  SET_CURRENT_CHANNEL,
  FETCH_ALL_CHANNELS_REQUEST,
  FETCH_ALL_CHANNELS_SUCCESS,
  FETCH_ALL_CHANNELS_FAILURE,
];

const EXPERTMODE_REDUCER_ACTION = [
  FETCH_EXPERTMODE_RUBRIQUES_REQUEST,
  FETCH_EXPERTMODE_RATING_REQUEST,
  FETCH_EXPERTMODE_TEMPLATE_REQUEST,
  FETCH_EXPERTMODE_SUCCESS,
  FETCH_EXPERTMODE_FAILURE,
  FETCH_EXPERTMODE_RATING_FAILURE,
  FETCH_EXPERTMODE_TIMELINE_FAILURE,
  SET_EXPERTMODE_HIGHLIGHT_MANIFEST,
  SET_EXPERTMODE_RUBRIQUES_RESPONSE,
  SET_EXPERTMODE_TEMPLATE_RESPONSE,
  SET_EXPERTMODE_MENU,
  SET_EXPERTMODE_RUBRIQUE_INDEX,
  SET_EXPERTMODE_CURRENT_TEMPLATE,
  SET_EXPERTMODE_SURVEY_RATING_CONTENT_ID,
  SET_EXPERT_MODE_TIMELINE_ACTIVE_QUESTION,
  CLEAN_EXPERTMODE_TIMELINE_ACTIVE_QUESTION,
  REFRESH_EXPERTMODE_TEMPLATE_REQUEST,
  TOGGLE_EXPERTMODE_RATING,
  CLEAN_EXPERTMODE_STRATES,
  CLEAN_EXPERTMODE_ERROR,
  CLEAN_EXPERTMODE_RATING_ERROR,
  CLEAR_EXPERTMODE_RUBRIQUES,
  CLEAN_EXPERTMODE_TIMELINE_ERROR,
  CLEAR_EXPERTMODE_STATUS,
  NOTIFY_EXPERTMODE,
  CLEAR_EXPERTMODE_NOTIFICATION,
  ADD_NEW_SURVEY_VOTE,
  ADD_NEW_TIMELINE_ANSWER,
  SET_EXPERTMODE_TIMELINE_ANSWER_REQUEST,
];

const USER_REDUCER_ACTIONS = [
  SET_PASS_TOKEN,
  SET_MICRO_ELIGIBILITY,
  SET_PASS_ID,
  SET_USERKEYID,
  SET_TRACKINGKEYID,
  SET_DEVICEKEYID,
  SET_PROFILEID,
  SET_APPSESSIONID,
  SET_LIVE_TOKEN,
  SET_STREAM_TOKEN,
  LOCK_PARENTAL_RATING5,
  UNLOCK_PARENTAL_RATING5,
  CHECK_PARENTAL_CODE,
  RESET_PARENTAL_CODE_CHECK,
  SET_PARENTAL_CODE_ERROR,
  SET_PARENTAL_CODE_USER_DATA,
  SET_PARENTAL_CODE_CREATION_QR_CODE_URL,
  FETCH_PARENTAL_CODE_CREATION_QR_CODE_URL,
  SET_PARENTAL_LINKS,
  FETCH_PARENTAL_LINKS,
  SET_UNLOCKED_BROADCAST_ID,
  SET_TOKEN_CMS,
  SET_AB_TEST_POPULATION,
  SET_MSD,
];

const SEQUENCE_REDUCER_ACTIONS = [
  SEQUENCE_INITIALIZE,
  GO_TO_NEXT_SEQUENCE,
  REPLACE_CURRENT_SEQUENCE,
  INSERT_CURRENT_SEQUENCE,
  SET_RECEIVER_SEQUENCE,
  SEQUENCE_INSERT_AFTER_CURRENT,
  STORE_SENDER_SEQUENCE,
  RELEASE_SENDER_SEQUENCE,
];

const DATA_REDUCER_ACTIONS = [
  FETCH_CONTENT_REQUEST,
  FETCH_CONTENT_SUCCESS,
  FETCH_CONTENT_FAILURE,
  MERGE_DATA,
  SET_AS_LIVE,
  SET_AS_PREMIUM,
  SET_IMAGES,
  SET_STREAM_EVENT_OVERLAY,
  SET_NEXT_EPISODE_ID,
  REMOVE_STREAM_EVENT_OVERLAY,
];

const ADS_REDUCER_ACTIONS = [
  FETCH_ADSERVER_REQUEST,
  FETCH_ADSERVER_SUCCESS,
  FETCH_ADSERVER_FAILURE,
  ADD_AD_ROUTINES_DATA,
  SET_CURRENT_AD_PLAYLIST,
  NEXT_AD,
  DECREMENT_REMAINING_TIME,
  SET_AD_FOCUS,
  HIDE_AD_SKIP_BUTTONS,
  SHOULD_SHOW_AD_SKIP_BUTTONS,
  RESET_AD,
  SET_VPAID,
  SET_AD_WILL_START_IN,
  RESET_AD_WILL_START_IN,
  SET_LAST_AD_BREAK_PLAY_DATE,
  INCREMENT_BUFFERING_NUMBER_COUNT,
  RESET_BUFFERING_NUMBER_COUNT,
  CAN_SKIP_CURRENT_AD,
  CAN_SKIP_ALL_ADS,
  SET_CAN_SKIP_AFTER,
  SET_SPONSOR_GENRE,
  SET_DISABLE_SPONSORS,
  SET_AD_SCHEDULE,
  SHOULD_SHOW_ANTI_AD_SKIP_NOTIFICATION,
  SET_STREAM_EVENT_ADS,
  REMOVE_STREAM_EVENT_ADS,
];

const TOOLTIP_REDUCER_ACTIONS = [
  TOOLTIP_MOUSE_SHOW,
  TOOLTIP_SHOW,
  TOOLTIP_HIDE,
  TOOLTIP_SET_CONTENT,
];

const PARENTAL_RATING_REDUCER_ACTIONS = [SET_PARENTAL_RATING_PAUSED_STATE];

const PROGRAM_REDUCER_ACTIONS = [
  SET_CURRENT_PROGRAM,
  SET_HAD_ERROR_ON_CURRENT_PROGRAM,
  SET_EMPTY_PROGRAM,
  SET_IS_ANTI_AD_SKIP_SEQUENCE,
  FETCH_CHANNEL_PROGRAMS_REQUEST,
  FETCH_CHANNEL_PROGRAMS_SUCCESS,
  SET_ZAPLIST_MENU,
  SET_ZAPLIST_MODE,
  SET_ZAPLIST_SEARCH_QUERY,
  SET_TIMELINE_MENU,
  SET_TIMELINE_MODE,
  SET_PREV_NEXT_PROGRAMS,
  FETCH_ALL_PROGRAMS_REQUEST,
  FETCH_ALL_PROGRAMS_SUCCESS,
];

const CONFIG_REDUCER_ACTIONS = [
  CONFIG_MERGE,
  CONFIG_SET_START_AT,
  CONFIG_RESET_LANGUAGE_PREFERENCES,
  CONFIG_SET_SUPPORTED_DRMS,
];

const FRAME_REDUCER_ACTIONS = [
  FRAME_SET_FOCUS,
  FRAME_SET_OFFSETS,
  FRAME_SET_SIZE,
  FRAME_SET_BREAKPOINT,
];

const AIRPLAY_REDUCER_ACTIONS = [TRIGGER_AIRPLAY, SET_AIRPLAY_AVAILABLE];

const MINIPLAYER_REDUCER_ACTIONS = [
  OPEN_MINIPLAYER_FULLFRAME,
  CLOSE_MINIPLAYER_FULLFRAME,
];

const DEVICE_REDUCER_ACTIONS = [
  DEVICE_SET_INFOS,
  DEVICE_SET_MOTION,
  DEVICE_SET_ORIENTATION,
];

const CAST_REDUCER_ACTIONS = [
  CAST_AVAILABLE,
  CAST_PENDING,
  CAST_IDLE_AFTER_PLAYING,
  CAST_SET_METADATA,
  CAST_SET_AVAILABLE_LANGUAGES,
  CAST_SET_AVAILABLE_SUBTITLES,
  CAST_SET_CURRENT_LANGUAGE,
  CAST_SET_CURRENT_SUBTITLE,
  CAST_SUCCEED,
  CAST_LOADED,
  CAST_FAILED,
  CAST_ENDED,
  CAST_REQUEST_START,
  CAST_REQUEST_STOP,
  CAST_SET_RECEIVER_NAME,
  CAST_SET_RECEIVER_ID,
  CAST_SET_CURRENT_REQUEST_FROM_RECEIVER,
  CAST_RECEIVER_ERROR,
  CAST_SET_RELOAD_CONTENT,
  CAST_LAST_TIME_BEFORE_END,
  CAST_SET_RECEIVER_REFERENCE_DATE_TIME,
];

const PINGLOG_REDUCER_ACTIONS = [
  PING_LOG_ERROR,
  SET_VIDEO_BITRATE,
  PING_LOG_SET_CONFIG,
  SET_STATE,
];

const SERIES_REDUCER_ACTIONS = [
  FETCH_SERIES_REQUEST,
  FETCH_SERIES_SUCCESS,
  SET_CURRENT_SEASON,
  SET_EPISODE_PROGRESS,
  SET_CURRENT_EPISODE_PROGRESS,
  RESET_TO_INITIAL_SEASON,
  SET_CURRENT_EPISODE_ID,
  SET_NEXT_EPISODE_ID,
  SET_SYNOPSIS_DISPLAY,
  ADD_SKIP_BUTTONS,
  DISABLE_FORCE_SHOW_SKIP_BUTTONS,
  RESET_SKIP_BUTTONS,
  HIDE_SKIP_BUTTONS,
  RESET_SERIES_DATA,
  CANCEL_COUNTDOWN_ENDCREDITS,
  RESET_COUNTDOWN_ENDCREDITS,
];

const SIDEPANEL_REDUCER_ACTIONS = [OPEN_SIDE_PANEL, CLOSE_SIDE_PANEL];

const MODAL_REDUCER_ACTIONS = [
  MODAL_OPEN,
  MODAL_TOGGLE,
  MODAL_CLOSE,
  SET_IS_MODAL_TO_BE_DISPLAYED_AGAIN,
];

const SWITCHPLUS_REDUCER_ACTIONS = [
  SET_STATE,
  ERROR_SHOW,
  SET_CURRENT_LANGUAGE,
  SET_CURRENT_SUBTITLE,
  SET_CURRENT_SUBTITLES_FONT_FAMILY,
  SET_CURRENT_SUBTITLES_FONT_TYPE,
  SET_CURRENT_SUBTITLES_SIZE,
  SET_CURRENT_SUBTITLES_PRESET,
  SET_CURRENT_CHANNEL,
  SET_FULLSCREEN,
  DEVICE_SET_ORIENTATION,
  AD_START_PLAYING,
  PLAY,
  PAUSE,
  MUTE,
  UNMUTE,
  SET_FULLSCREEN,
  SEEK_TO_MINUS_FIXED_DURATION_REQUEST,
  SEEK_TO_PLUS_FIXED_DURATION_REQUEST,
  SET_PLAYBACK_RATE,
  TRIGGER_EXIT,
];

const UI_MANAGER_REDUCER_ACTIONS = [
  ADD_NAVIGATION_HISTORY,
  REMOVE_NAVIGATION_HISTORY,
  RESET_NAVIGATION_HISTORY,
  UPDATE_DISPLAY_STATE,
  UPDATE_FOCUSED_ELEMENT,
  GO_BACK,
  RESET_UI,
  ON_DOWN_EXIT_BUTTONS,
  ON_UP_EXIT_PANEL,
  OPEN_PANEL,
  UPDATE_PANEL_PARAMS,
  MODAL_OPEN,
  MODAL_TOGGLE,
  MODAL_CLOSE,
  SET_IS_MODAL_TO_BE_DISPLAYED_AGAIN,
  SET_IS_LG_POINTER_ENABLED,
];

const MULTI_LIVE_ACTIONS = [
  MULTI_PLAYER_REQUEST,
  SET_MULTI_LIVE_DATA,
  SET_MULTI_HAPI_DATA,
];

export const actionTypes = {
  CLEAR_MORE_INFOS,
  FETCH_MORE_INFOS_REQUEST,
  FETCH_MORE_INFOS_FAILURE,
  RESET_MORE_INFOS_ERROR,
  FETCH_MORE_INFOS_SUCCESS,
  START_APP,
  SET_STATE,
  SET_STREAMING_TYPE,
  SET_AVAILABLE_LANGUAGES,
  SET_AVAILABLE_SUBTITLES,
  SET_AVAILABLE_VIDEO_BITRATES,
  SET_CURRENT_LANGUAGE,
  SET_PREFERRED_LANGUAGE,
  SET_CURRENT_SUBTITLE,
  SET_PREFERRED_SUBTITLE,
  SET_CURRENT_SUBTITLES_FONT_FAMILY,
  SET_CURRENT_SUBTITLES_FONT_TYPE,
  SET_CURRENT_SUBTITLES_SIZE,
  SET_CURRENT_SUBTITLES_PRESET,
  TOGGLE_VIDEO_DISPLAY,
  SET_VOLUME,
  SET_LIVE_GAP,
  SET_SERVER_LIVE_TIME_OFFSET,
  SET_SERVER_TIMESTAMP,
  SET_LIVE_START_TIME,
  MUTE,
  UNMUTE,
  SET_PLAYBACK_TIME,
  SET_PLAYBACK_RATE,
  SET_LOADED_TIME,
  SET_BUFFER_GAP,
  SET_CURRENT_TIME,
  SET_MINIMUM_POSITION,
  SET_START_AT_ERROR,
  SET_LIVE_TIME,
  SET_VIDEO_DURATION,
  SET_CORE_PLAYER_TYPE,
  PLAY,
  STOP,
  REQUEST_VIDEO_PRELOAD,
  RESET_VIDEO_PRELOAD,
  SET_DIRECTFILE_REQUEST,
  SET_DIRECTFILE_SUCCESS,
  SET_DIRECTFILE_FAILURE,
  SET_DIRECTFILE,
  SET_MANIFEST_REQUEST,
  SET_MANIFEST_SUCCESS,
  SET_MANIFEST_FAILURE,
  SET_MANIFEST,
  SET_MANIFEST_FROM_RECEIVER_REQUEST,
  SET_MANIFEST_FROM_RECEIVER,
  UPDATE_GOOGLE_DAI_URL,
  PAUSE,
  RESTART_REQUEST,
  SEEK_TO,
  SEEK_TO_MINUS_FIXED_DURATION_REQUEST,
  SEEK_TO_PLUS_FIXED_DURATION_REQUEST,
  SET_KEY_PRESS_LISTENER_STATUS,
  GO_TO_LIVE_REQUEST,
  SET_FULLSCREEN,
  SET_ACTIVITY,
  RESUME_AD,
  PAUSE_AD,
  SET_AUDIO_BITRATE,
  SET_VIDEO_BITRATE,
  SET_MAX_VIDEO_BITRATE,
  SET_VIDEO_QUALITY_PROFILE,
  SET_CURRENT_KEY_SYSTEM,
  SET_CURRENT_LOCATION,
  SET_CURRENT_DISTRIBUTION,
  SET_ESTIMATED_VIDEO_BITRATE,
  SET_ESTIMATED_AUDIO_BITRATE,
  SET_PASS_TOKEN,
  SET_MICRO_ELIGIBILITY,
  SET_PASS_ID,
  SET_USERKEYID,
  SET_TRACKINGKEYID,
  SET_DEVICEKEYID,
  SET_PROFILEID,
  SET_APPSESSIONID,
  SET_LIVE_TOKEN,
  SET_STREAM_TOKEN,
  SET_AS_LIVE,
  SET_AS_PREMIUM,
  SET_AB_TEST_POPULATION,
  SET_MSD,
  LOCK_PARENTAL_RATING5,
  UNLOCK_PARENTAL_RATING5,
  CHECK_PARENTAL_CODE,
  RESET_PARENTAL_CODE_CHECK,
  SET_PARENTAL_CODE_ERROR,
  SET_PARENTAL_CODE_USER_DATA,
  SET_PARENTAL_CODE_CREATION_QR_CODE_URL,
  FETCH_PARENTAL_CODE_CREATION_QR_CODE_URL,
  SET_PARENTAL_LINKS,
  FETCH_PARENTAL_LINKS,
  SET_UNLOCKED_BROADCAST_ID,
  SET_TOKEN_CMS,
  LOAD_BIF,
  SET_IMAGES,
  SET_STREAM_EVENT_OVERLAY,
  REMOVE_STREAM_EVENT_OVERLAY,
  FETCH_CONTENT_REQUEST,
  FETCH_CONTENT_SUCCESS,
  FETCH_CONTENT_FAILURE,
  FETCH_ALL_CHANNELS_REQUEST,
  FETCH_ALL_CHANNELS_SUCCESS,
  FETCH_ALL_CHANNELS_FAILURE,
  FETCH_CHANNEL_PROGRAMS_REQUEST,
  FETCH_CHANNEL_PROGRAMS_SUCCESS,
  FETCH_CHANNEL_PROGRAMS_FAILURE,
  FETCH_ALL_PROGRAMS_REQUEST,
  FETCH_ALL_PROGRAMS_SUCCESS,
  FETCH_ALL_PROGRAMS_FAILURE,
  SET_EXPERTMODE_HIGHLIGHT_MANIFEST,
  SET_ZAPLIST_MODE,
  SET_ZAPLIST_MENU,
  SET_ZAPLIST_SEARCH_QUERY,
  SET_CURRENT_CHANNEL_FROM_PROGRAM_REQUEST,
  SET_CURRENT_CHANNEL_REQUEST,
  SET_CURRENT_CHANNEL,
  RELOAD_CURRENT_CHANNEL,
  SET_CURRENT_PROGRAM_REQUEST,
  SET_CURRENT_PROGRAM,
  SET_HAD_ERROR_ON_CURRENT_PROGRAM,
  SET_IS_ANTI_AD_SKIP_SEQUENCE,
  SET_TIMELINE_MODE,
  SET_TIMELINE_MENU,
  SET_PREV_NEXT_PROGRAMS,
  SET_EMPTY_PROGRAM,
  REQUEST_UNMUTE_WITH_MOBILE_POPIN,
  ERROR_SHOW,
  ERROR_RESET,
  START_RELOAD_COUNTDOWN,
  DECREMENT_RELOAD_COUNTDOWN,
  RELOAD_PLAYER,
  TOOLTIP_SET_POSITION,
  TOOLTIP_MOUSE_SHOW,
  TOOLTIP_SHOW,
  TOOLTIP_HIDE,
  TOOLTIP_SET_CONTENT,
  SPINNER_SHOW,
  SPINNER_HIDE,
  FRAME_SET_OFFSETS,
  FRAME_SET_SIZE,
  FRAME_SET_FOCUS,
  FRAME_SET_BREAKPOINT,
  MERGE_DATA,
  CONFIG_MERGE,
  RESET_START_AT_ERROR,
  CONFIG_SET_START_AT,
  CONFIG_RESET_LANGUAGE_PREFERENCES,
  CONFIG_SET_SUPPORTED_DRMS,
  SET_KEY_MAPPING,
  KEY_PRESS,
  SEQUENCE_PRESS,
  GO_TO_NEXT_SEQUENCE,
  SEQUENCE_INITIALIZE,
  POSTER_SEQUENCE_REQUEST,
  CONTENT_SEQUENCE_REQUEST,
  SEQUENCE_INSERT_AFTER_CURRENT,
  REPLACE_CURRENT_SEQUENCE,
  SET_RECEIVER_SEQUENCE,
  STORE_SENDER_SEQUENCE,
  RELEASE_SENDER_SEQUENCE,
  ENTER_CAST_SEQUENCE_REQUEST,
  LEAVE_CAST_SEQUENCE_REQUEST,
  INSERT_CURRENT_SEQUENCE,
  DEVICE_SET_INFOS,
  DEVICE_SET_MOTION,
  DEVICE_SET_ORIENTATION,
  FETCH_ADSERVER_REQUEST,
  FETCH_ADSERVER_SUCCESS,
  FETCH_ADSERVER_FAILURE,
  ADD_AD_ROUTINES_DATA,
  SET_CURRENT_AD_PLAYLIST,
  ABORT_AD,
  NEXT_AD,
  DECREMENT_REMAINING_TIME,
  TRACK_CLICK,
  SET_AD_FOCUS,
  HIDE_AD_SKIP_BUTTONS,
  SHOULD_SHOW_AD_SKIP_BUTTONS,
  SET_VPAID,
  RESET_AD,
  SET_AD_WILL_START_IN,
  RESET_AD_WILL_START_IN,
  SET_LAST_AD_BREAK_PLAY_DATE,
  INCREMENT_BUFFERING_NUMBER_COUNT,
  RESET_BUFFERING_NUMBER_COUNT,
  CAN_SKIP_CURRENT_AD,
  CAN_SKIP_ALL_ADS,
  SET_CAN_SKIP_AFTER,
  SET_SPONSOR_GENRE,
  SET_DISABLE_SPONSORS,
  OPEN_SIDE_PANEL,
  CLOSE_SIDE_PANEL,
  MODAL_OPEN,
  MODAL_CLOSE,
  MODAL_TOGGLE,
  ADD_NAVIGATION_HISTORY,
  REMOVE_NAVIGATION_HISTORY,
  RESET_NAVIGATION_HISTORY,
  UPDATE_DISPLAY_STATE,
  UPDATE_FOCUSED_ELEMENT,
  GO_BACK,
  RESET_UI,
  ON_DOWN_EXIT_BUTTONS,
  ON_UP_EXIT_PANEL,
  OPEN_PANEL,
  UPDATE_PANEL_PARAMS,
  SET_IS_MODAL_TO_BE_DISPLAYED_AGAIN,
  SET_IS_LG_POINTER_ENABLED,
  FETCH_SERIES_REQUEST,
  FETCH_SERIES_SUCCESS,
  SET_CURRENT_SEASON,
  SET_EPISODE_PROGRESS,
  SET_CURRENT_EPISODE_PROGRESS,
  RESET_TO_INITIAL_SEASON,
  SET_CURRENT_EPISODE_ID,
  SET_NEXT_EPISODE_ID,
  SET_SYNOPSIS_DISPLAY,
  LOAD_EPISODE_REQUEST,
  LOAD_NEXT_EPISODE_REQUEST,
  ADD_SKIP_BUTTONS,
  DISABLE_FORCE_SHOW_SKIP_BUTTONS,
  RESET_SKIP_BUTTONS,
  HIDE_SKIP_BUTTONS,
  RESET_SERIES_DATA,
  CANCEL_COUNTDOWN_ENDCREDITS,
  RESET_COUNTDOWN_ENDCREDITS,
  SHOW_CONTEXT_MENU,
  HIDE_CONTEXT_MENU,
  TOGGLE_RX_PLAYER_DEBUG_MODAL,
  PING_LOG_ERROR,
  PING_LOG_SET_CONFIG,
  SWITCH_PLUS_TAG_ACTION,
  SWITCH_PLUS_FLUSH,
  INIT_PREMIUM_LIVE_REQUEST,
  INIT_PREMIUM_CATCHUP_REQUEST,
  MULTI_PLAYER_REQUEST,
  SET_MULTI_LIVE_DATA,
  SET_MULTI_HAPI_DATA,
  SET_LIVE_TV_IS_INITIATED,
  STOP_LIVE_TV,
  START_POLL_PROGRAMS_INTERVAL,
  STOP_POLL_PROGRAMS_INTERVAL,
  TRIGGER_EXIT,
  DISABLED_ELEMENT_TRIGGERED,
  CAST_AVAILABLE,
  CAST_PENDING,
  CAST_IDLE_AFTER_PLAYING,
  CAST_SET_METADATA,
  CAST_SET_AVAILABLE_LANGUAGES,
  CAST_SET_AVAILABLE_SUBTITLES,
  CAST_SET_CURRENT_LANGUAGE,
  CAST_SET_CURRENT_SUBTITLE,
  CAST_SUCCEED,
  CAST_FAILED,
  CAST_ENDED,
  CAST_REQUEST_START,
  CAST_REQUEST_STOP,
  CAST_LOADED,
  CAST_LAST_TIME_BEFORE_END,
  CAST_SET_RECEIVER_NAME,
  CAST_SET_RECEIVER_ID,
  CAST_SET_CURRENT_REQUEST_FROM_RECEIVER,
  CAST_RECEIVER_ERROR,
  CAST_SET_RELOAD_CONTENT,
  CAST_SET_RECEIVER_REFERENCE_DATE_TIME,
  TRIGGER_AIRPLAY,
  SET_AIRPLAY_AVAILABLE,
  DISPOSE,
  TOGGLE_EXPERIMENTAL_FEATURE,
  SET_CORE_PLAYER_VERSION,
  SET_SECURITY_LEVEL,
  SET_SHOULD_NOT_FETCH_UHD_PLAYSET,
  SET_IS_CORE_PLAYER_LOCKED,
  FETCH_EXPERTMODE_RATING_REQUEST,
  FETCH_EXPERTMODE_RUBRIQUES_REQUEST,
  FETCH_EXPERTMODE_TEMPLATE_REQUEST,
  FETCH_EXPERTMODE_SUCCESS,
  ADD_NEW_SURVEY_VOTE,
  ADD_NEW_TIMELINE_ANSWER,
  SET_EXPERTMODE_TIMELINE_ANSWER_REQUEST,
  FETCH_EXPERTMODE_FAILURE,
  FETCH_EXPERTMODE_RATING_FAILURE,
  FETCH_EXPERTMODE_TIMELINE_FAILURE,
  SET_EXPERTMODE_RUBRIQUES_RESPONSE,
  SET_EXPERTMODE_TEMPLATE_RESPONSE,
  SET_EXPERTMODE_MENU,
  SET_EXPERTMODE_RUBRIQUE_INDEX,
  SET_EXPERTMODE_CURRENT_TEMPLATE,
  SET_EXPERTMODE_SURVEY_RATING_CONTENT_ID,
  SET_EXPERT_MODE_TIMELINE_ACTIVE_QUESTION,
  REFRESH_EXPERTMODE_TEMPLATE_REQUEST,
  TOGGLE_EXPERTMODE_RATING,
  CLEAN_EXPERTMODE_STRATES,
  CLEAN_EXPERTMODE_ERROR,
  CLEAN_EXPERTMODE_RATING_ERROR,
  CLEAR_EXPERTMODE_RUBRIQUES,
  CLEAN_EXPERTMODE_TIMELINE_ACTIVE_QUESTION,
  CLEAN_EXPERTMODE_TIMELINE_ERROR,
  CLEAR_EXPERTMODE_STATUS,
  SEND_EXPERTMODE_TEMPLATE_TRACKING,
  NOTIFY_EXPERTMODE,
  CLEAR_EXPERTMODE_NOTIFICATION,
  FETCH_EXPERTMODE,
  SET_WAITING_NOTIFICATION,
  CLEAR_WAITING_NOTIFICATION,
  SET_UILANGUAGE,
  SET_UILANGUAGE_AND_PHRASES,
  SET_PIP_IS_ACTIVE,
  AD_START_PLAYING,
  SET_AD_SCHEDULE,
  SET_STREAM_EVENT_ADS,
  REMOVE_STREAM_EVENT_ADS,
  SHOULD_SHOW_ANTI_AD_SKIP_NOTIFICATION,
  SET_PARENTAL_RATING_PAUSED_STATE,
  SET_LAST_TRACKED_USER_COMMAND,
  RESET_LAST_TRACKED_USER_COMMAND,
  OPEN_MINIPLAYER_FULLFRAME,
  CLOSE_MINIPLAYER_FULLFRAME,
  // Grouped actions
  CORE_REDUCER_ACTIONS,
  CHANNEL_REDUCER_ACTIONS,
  EXPERTMODE_REDUCER_ACTION,
  USER_REDUCER_ACTIONS,
  SEQUENCE_REDUCER_ACTIONS,
  DATA_REDUCER_ACTIONS,
  ADS_REDUCER_ACTIONS,
  TOOLTIP_REDUCER_ACTIONS,
  PARENTAL_RATING_REDUCER_ACTIONS,
  PROGRAM_REDUCER_ACTIONS,
  CONFIG_REDUCER_ACTIONS,
  FRAME_REDUCER_ACTIONS,
  AIRPLAY_REDUCER_ACTIONS,
  MINIPLAYER_REDUCER_ACTIONS,
  DEVICE_REDUCER_ACTIONS,
  CAST_REDUCER_ACTIONS,
  PINGLOG_REDUCER_ACTIONS,
  SERIES_REDUCER_ACTIONS,
  SIDEPANEL_REDUCER_ACTIONS,
  MODAL_REDUCER_ACTIONS,
  SWITCHPLUS_REDUCER_ACTIONS,
  UI_MANAGER_REDUCER_ACTIONS,
  MULTI_LIVE_ACTIONS,
} as const;
