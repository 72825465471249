export const NO_ADS = 'NO_ADS';
export const NO_ADS_SUPPORTED = 'NO_ADS_SUPPORTED';
export const AD_BLOCKER_DETECTED = 'AD_BLOCKER_DETECTED';
export const PARSING_ADS_ERROR = 'PARSING_ADS_ERROR';
export const NO_PARSABLE_ADS = 'NO_PARSABLE_ADS';

export const ENCRYPTED = 'CRYPTED';
export const PARENTAL_CODE_REQUIRED = 'PARENTAL-CODE-REQUIRED';
export const PARENTAL_RATING_RESTRICTED_HOURS =
  'PARENTAL-RATING-RESTRICTED-HOURS';
export const UNAUTHORIZED_PARENTAL_RATING = 'UNAUTHORIZED-PARENTAL-RATING';
export const UNAUTHORIZED_RATING = 'UNAUTHORIZED_RATING';
export const PARENTAL_RATING_RESTRICTED_DEVICE =
  'PARENTAL-RATING-RESTRICTED-DEVICE';
export const MEDIA_ERROR = 'MEDIA_ERROR';
export const ERROR_FROM_CORE_PLAYER = 'ERROR_FROM_CORE_PLAYER';
export const FAILED_LOADED_WITH_UHD_PLAYSET = 'FAILED_LOADED_WITH_UHD_PLAYSET';
export const FAILED_LOADED_WITH_GIVEN_KEY_SYSTEM =
  'FAILED_LOADED_WITH_GIVEN_KEY_SYSTEM';

export const UNAUTHORIZED_LIVE = 'UNAUTHORIZED-LIVE';
export const UNAUTHORIZED_STARTOVER = 'UNAUTHORIZED-STARTOVER';
export const UNAUTHORIZED_CHANNEL = 'UNAUTHORIZED-CHANNEL';
export const LIVE_RESTRICTED_DEVICE = 'LIVE-RESTRICTED-DEVICE';

export const MULTI_UNAUTHORIZED_CONTENT = 'MULTI_UNAUTHORIZED_CONTENT';
export const MULTI_ENCRYPTED_CONTENT = 'MULTI_ENCRYPTED_CONTENT';

export const NO_STREAM_ERROR_CODES = [
  ENCRYPTED,
  PARENTAL_CODE_REQUIRED,
  PARENTAL_RATING_RESTRICTED_HOURS,
];

export const SILENT_ERRORS = [
  FAILED_LOADED_WITH_UHD_PLAYSET,
  FAILED_LOADED_WITH_GIVEN_KEY_SYSTEM,
];

// -- This constant makes a mapping between certain errors with a oP error code  -- //
// -- so it is easily identified and dispatch back in our team -- //
export const OPES_ERROR_CODES: Record<string, Record<string, string>> = {
  internal: {
    config: 'OPES 11',
    import: 'OPES 12',
  },
  lightLive: {
    unknown: 'OPES 20',
    CRYPTED: 'OPES 21',
    'LIVE-ENDED': 'OPES 22',
  },
  session: {
    EXPIRED: 'OPES 31',
    INACTIVITY: 'OPES 32',
  },
  hapi: {
    default: 'OPES 100',
    'CONSO-404-0': 'OPES 101',
    'CONSO-404-1': 'OPES 102',
    'CATA-404-0': 'OPES 120',
    'CATA-404-1': 'OPES 121',
    'MDRM-403-3': 'OPES 130',
    'MDRM-403-4': 'OPES 131',
    'AUTH-403-1': 'OPES 140',
    'AUTH-403-2': 'OPES 141',
    'AUTH-403-3': 'OPES 142',
    'CONSO-403-3': 'OPES 150',
    'CONSO-403-4': 'OPES 151',
    'CONSO-403-152': 'OPES 152',
    'PERSO-1': 'OPES 160',
    'PERSO-2': 'OPES 161',
    'PERSO-3': 'OPES 162',
    'PERSO-4': 'OPES 163',
    'PERSO-5': 'OPES 164',
    'PERSO-6': 'OPES 165',
    'PERSO-7': 'OPES 166',
    'PASS-1': 'OPES 170',
    'PARENTAL-CODE-REQUIRED': 'OPES 180',
  },
  live: {
    'UNAVAILABLE-CHANNEL': 'OPES 201',
    'INEXISTENT-CHANNEL': 'OPES 202',
    'UNAUTHORIZED-CHANNEL': 'OPES 203 .',
    'PARENTAL-RATING-RESTRICTED-HOURS': 'OPES 204',
    'PARENTAL-RATING-RESTRICTED-DEVICE': 'OPES 205',
    'PARENTAL-CODE-REQUIRED': 'OPES 206',
    'UNAUTHORIZED-CRYPTED': 'OPES 207',
    'UNAUTHORIZED-PARENTAL-RATING': 'OPES 208',
    'UNAUTHORIZED-LIVE': 'OPES 209',
    'LIVE-RESTRICTED-DEVICE': 'OPES 210',
    'UNAUTHORIZED-STARTOVER': 'OPES 211',
    'UNAUTHORIZED-STARTOVER-CHANNEL': 'OPES 212',
    'UNAVAILABLE-MANIFEST-URL': 'OPES 213',
    'EMPTY-PDS': 'OPES 214',
    'LIVE-MISSING-PASSTOKEN': 'OPES 215',
    'UNAVAILABLE-LIVE-TV': 'OPES 216',
    'PASS-1': 'OPES 217',
    CRYPTED: 'OPES 218',
    'DRM-1': 'OPES 230',
    'DRM-2': 'OPES 231',
    'DRM-3': 'OPES 232',
    'DRM-4': 'OPES 233',
    'DRM-5': 'OPES 234',
    'DRM-6': 'OPES 235',
    'DRM-7': 'OPES 236',
    'DRM-9': 'OPES 237',
    'LIVE-1': 'OPES 240',
    'LIVE-3': 'OPES 241',
    'LIVE-4': 'OPES 242',
    'LIVE-5': 'OPES 243',
    'LIVE-9': 'OPES 244',
  },
  airplay: {
    MEDIA_ERROR: 'OPES 301',
  },
  core: {
    default: 'OPES 911',
    NETWORK_ERROR: 'OPES 401',
    NETWORK_ERROR_FORBIDDEN: 'OPES 402',
    INCOMPATIBLE_KEYSYSTEMS: 'OPES 403',
    PIPELINE_LOAD_ERROR: 'OPES 404',
    OTHER_ERROR: 'OPES 405',
    LICENSE_SERVER_CERTIFICATE_ERROR: 'OPES 406',
    CREATE_MEDIA_KEYS_ERROR: 'OPES 407',
    DISCONTINUITY_ENCOUNTERED: 'OPES 408',
    NO_PLAYABLE_REPRESENTATION: 'OPES 409',
    INVALID_ENCRYPTED_EVENT: 'OPES 410',
    BUFFER_FULL_ERROR: 'OPES 411',
    BUFFER_TYPE_UNKNOWN: 'OPES 412',
    BUFFER_APPEND_ERROR: 'OPES 413',
    MANIFEST_INCOMPATIBLE_CODECS_ERROR: 'OPES 414',
    MANIFEST_PARSE_ERROR: 'OPES 415',
    MANIFEST_UNSUPPORTED_ADAPTATION_TYPE: 'OPES 416',
    MANIFEST_UPDATE_ERROR: 'OPES 417',
    KEY_LOAD_ERROR: 'OPES 418',
    KEY_ERROR: 'OPES 419',
    KEY_UPDATE_ERROR: 'OPES 420',
    KEY_GENERATE_REQUEST_ERROR: 'OPES 421',
    KEY_LOAD_TIMEOUT: 'OPES 422',
    KEY_STATUS_CHANGE_ERROR: 'OPES 423',
    MEDIA_ERR_ABORTED: 'OPES 424',
    MEDIA_ERR_BLOCKED_AUTOPLAY: 'OPES 425',
    MEDIA_ERR_PLAY_NOT_ALLOWED: 'OPES 426',
    MEDIA_ERR_NOT_LOADED_METADATA: 'OPES 427',
    MEDIA_ERR_NETWORK: 'OPES 428',
    MEDIA_ERR_SRC_NOT_SUPPORTED: 'OPES 429',
    MEDIA_ERR_UNKNOWN: 'OPES 430',
    MEDIA_KEYS_NOT_SUPPORTED: 'OPES 431',
    MEDIA_SOURCE_NOT_SUPPORTED: 'OPES 432',
    MEDIA_STARTING_TIME_NOT_FOUND: 'OPES 433',
    MEDIA_TIME_BEFORE_MANIFEST: 'OPES 434',
    MEDIA_TIME_AFTER_MANIFEST: 'OPES 435',
    MEDIA_TIME_NOT_FOUND: 'OPES 436',
    MEDIA_IS_ENCRYPTED_ERROR: 'OPES 437',
    MEDIA_ERR_DECODE: 'OPES 438',
    MEDIA_KEYERR_UNKNOWN: 'OPES 439',
    MEDIA_KEYERR_CLIENT: 'OPES 440',
    MEDIA_KEYERR_SERVICE: 'OPES 441',
    MEDIA_KEYERR_OUTPUT: 'OPES 442',
    MEDIA_KEYERR_HARDWARECHANGE: 'OPES 443',
    MEDIA_KEYERR_DOMAIN: 'OPES 444',
    PIPELINE_PARSE_ERROR: 'OPES 445',
    INTEGRITY_ERROR: 'OPES 446',
    MEDIA_KEYS_ATTACHMENT_ERROR: 'OPES 447',
    MULTIPLE_SESSIONS_SAME_INIT_DATA: 'OPES 448',
    INVALID_KEY_SYSTEM: 'OPES 449',
  },
  ads: {
    antiadblock: 'OPES 601',
  },
  pingLog: {
    'PPL:1': 'OPES 701',
    'PPL:-1': 'OPES 702',
    'PPL:-2': 'OPES 703',
  },
};
